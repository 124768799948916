import Cookies from 'js-cookie';
import getUserInformations from './get_user_informations.js';
import getUserProfiles from './get_user_profiles.js';

const userID = Cookies.get('DARTYIDS');
const $login = document.querySelector('.login');
const $label = $login.querySelector('.label');
const $loginPopin = document.querySelector('.login-popin');
let firstName;
let lastName;

const showUserInfos = (data) => {
    firstName = data.firstName.slice(0, 1);
    lastName = data.lastName;
    $label.innerHTML = `${firstName}. ${lastName}`;
    $label.classList.add('js-label-active');
    $loginPopin.classList.remove('js-hidden');
    if ('dartyMaxSubscription' in data && data.dartyMaxSubscription.status === 'ACTIVE') {
        $login.classList.add('js-dmax');
    }
};

const removeUserInfos = () => {
    $loginPopin.remove();
};

export default function userInformationsInit() {
    if (userID !== undefined) {
        getUserInformations().then((userDatas) => {
            showUserInfos(userDatas);
        });
    } else {
        removeUserInfos();
    }
}

export function getCustomerAccountStatus(userLogged) {
    const isOppNewCustomer = Cookies.get('OPPNEWCUSTOMER') !== undefined;
    let customerAccountStatus;

    if (userLogged === '1') {
        if (isOppNewCustomer) {
            customerAccountStatus = 'nouveau client';
        } else {
            customerAccountStatus = 'compte actif';
        }
    } else {
        customerAccountStatus = 'non logue';
    }

    return customerAccountStatus;
}

function isEmpty(obj) {
    let prop;
    for (prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return true;
}

export function getUserTrackingDatas(data) {
    const userData = {
        email: data.encryptedEmail ? data.encryptedEmail : '',
        logged: data.encryptedEmail ? '1' : '0',
        basket: data.numberOfProducts > 0 ? '1' : '0',
        wishlist: data.wishlistsCount > 0 ? '1' : '0',
        store: !isEmpty(data.favoriteStores) > 0 ? data.favoriteStores[0] : '',
        profiles: getUserProfiles(data)
    };

    return userData;
}
