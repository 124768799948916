import Cookies from 'js-cookie';

const $devisIndicator = document.getElementById('js-devis-indicator');
const $linkDevis = document.querySelector('.link.devis');
const pageList = document.querySelector('body#products-list');
const pageArticle = document.querySelector('body#page-product');
let codics;
let codicsLength = 0;

const getDevisCodics = () => {
    codics = Cookies.get('DARTY_COM_DEVIS');
    return codics;
};

const getDartyClic = () => {
    let dartyClic;
    if (pageList && pageList.id !== '') {
        dartyClic = '#dartyclic=PL_header_devis';
    } else if (pageArticle && pageArticle.id !== '') {
        dartyClic = '#dartyclic=FA_header_devis';
    }

    return dartyClic;
};

const getDevisPageUrl = () => {
    const codicsCookie = getDevisCodics();
    let url = '/nav/extra/devis';

    if (codicsCookie) {
        const codicsArray = codicsCookie.split(':');
        const codicsParam = codicsArray.join(':');
        url += `?codics=${codicsParam}`;
    }

    return url;
};

const numberOfProductsInDevis = (codicsCookie) => {
    if (codicsCookie !== undefined) {
        codicsLength = codicsCookie.split(':').length;
    }
    return codicsLength;
};

export const updateDevisIndicator = () => {
    getDevisCodics();
    const numberOfProducts = numberOfProductsInDevis(codics);

    if (!$devisIndicator) return;

    if (numberOfProducts > 0) {
        $devisIndicator.classList.add('show');
        $devisIndicator.textContent = numberOfProducts;

        if (numberOfProducts > 9) {
            $devisIndicator.classList.add('indicator-full');
        }

        // maj de l'url du lien devis
        $linkDevis.href = getDevisPageUrl() + getDartyClic();
    }

    $linkDevis.href = getDevisPageUrl() + getDartyClic();
};

export default function devisIndicatorInit() {
    updateDevisIndicator();
}
