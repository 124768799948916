import SaveGeoloc from './save_geoloc.js';

const $suggestions = document.querySelector('.js-suggestion-zipcode');
const $invalidErrorMessage = document.querySelector('.js-error-invalid');
let arraySuggestions = {};
let selectedSuggestion = null;
let currentIndexSuggestion = 0;

function hideAllErrorMessages() {
    const errorMessages = document.querySelectorAll('.searchbar .error-message');
    errorMessages.forEach((message) => {
        message.classList.remove('js-show');
    });
}

// permet de recuperer l'index de la suggestion ciblée
function getIndexSuggestion(suggestion) {
    return arraySuggestions.findIndex((arraySuggestion) => arraySuggestion.label === suggestion.label);
}
// permet de recuperer la suggestion suivante
function getNextSuggestion() {
    currentIndexSuggestion = currentIndexSuggestion < arraySuggestions.length - 1 ? currentIndexSuggestion + 1 : 0;
    return arraySuggestions[currentIndexSuggestion];
}
// permet de recuperer la suggestion précédente
function getPrevSuggestion() {
    currentIndexSuggestion = currentIndexSuggestion > 0 ? currentIndexSuggestion - 1 : arraySuggestions.length - 1;
    return arraySuggestions[currentIndexSuggestion];
}
// Navigation dans les suggestions, permet de recuperer la suggestion en fonction du move (prev ou next)
function getPrevNextSuggestion(currentSuggestion, move) {
    let prevNextSuggestion = null;
    let indexSuggestion = 0;
    const $suggestionList = $suggestions.querySelector('.suggestions_list').children;
    const suggestionItems = [...$suggestionList];

    // par defaut au premier move on cible l'index 0
    if (!selectedSuggestion) {
        indexSuggestion = 0;
        [prevNextSuggestion] = arraySuggestions;
    } // sinon on go à +1 ou -1 pour naviguer
    else {
        prevNextSuggestion =
            move === 'prev' ? getPrevSuggestion(currentSuggestion) : getNextSuggestion(currentSuggestion);
        indexSuggestion = getIndexSuggestion(prevNextSuggestion);
    }
    // on rend active la suggestion concernée en lui donnant le focus et en forcant sa visibilité dans la liste
    suggestionItems.forEach((suggestion) => suggestion.classList.remove('active'));
    $suggestionList.item(indexSuggestion).classList.add('active');
    $suggestionList.item(indexSuggestion).focus();
    $suggestionList.item(indexSuggestion).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });

    return prevNextSuggestion;
}

function getCitiesData(zipCode, sourceUrl, callback) {
    fetch(`${sourceUrl}?zipCode=${zipCode}`, { method: 'GET' })
        .then((response) => response.json())
        .then((data) => {
            arraySuggestions = data.cities.map((dataItem) => ({
                label: `${dataItem.name} (${dataItem.zipCode})`,
                value: dataItem
            }));

            hideAllErrorMessages();

            if (!arraySuggestions.length) {
                $invalidErrorMessage.classList.add('js-show');
            } else {
                callback(arraySuggestions);
            }
        })
        .catch((error) => {
            console.error('Error fetching cities data:', error);
            $invalidErrorMessage.classList.add('js-show');
        });
}

function updateInputElementValue(inputElement, value) {
    // eslint-disable-next-line no-param-reassign
    inputElement.value = value;
}
function displaySuggestions(inputElement, suggestions) {
    const suggestionsList = document.createElement('ul');
    suggestionsList.classList.add('suggestions_list');

    suggestions.forEach((suggestion) => {
        const suggestionItem = document.createElement('li');
        suggestionItem.classList.add('suggestions_list--item');
        suggestionItem.textContent = suggestion.label;

        suggestionItem.addEventListener('click', () => {
            updateInputElementValue(inputElement, suggestion.label);
            $suggestions.classList.add('suggestion_hidden');
            SaveGeoloc(suggestion.value);
            arraySuggestions = {};
            window.location.reload();
        });

        suggestionsList.appendChild(suggestionItem);
    });

    $suggestions.innerHTML = '';
    $suggestions.appendChild(suggestionsList);
    $suggestions.classList.remove('suggestion_hidden');
}

function validAutocomplete() {
    $suggestions.classList.add('suggestion_hidden');
    arraySuggestions = {};
    selectedSuggestion = null;
    currentIndexSuggestion = 0;
    window.location.reload();
}

export default function geolocAutocomplete(inputElement, serviceUrl) {
    inputElement.addEventListener('input', (event) => {
        const term = event.target.value;

        if (term.length === 0) {
            $suggestions.classList.add('suggestion_hidden');
        } else if (term.length >= 5) {
            getCitiesData(term, serviceUrl, (suggestions) => {
                displaySuggestions(inputElement, suggestions);
            });
        }
    });

    inputElement.addEventListener('keydown', (event) => {
        if (event.key === 'ArrowDown') {
            selectedSuggestion = getPrevNextSuggestion(selectedSuggestion, 'next') || null;
            if (selectedSuggestion) {
                updateInputElementValue(inputElement, selectedSuggestion.label);
            }
        }

        if (event.key === 'ArrowUp') {
            selectedSuggestion = getPrevNextSuggestion(selectedSuggestion, 'prev') || null;
            if (selectedSuggestion) {
                updateInputElementValue(inputElement, selectedSuggestion.label);
            }
        }

        if (event.key === 'Enter') {
            if (selectedSuggestion) {
                SaveGeoloc(selectedSuggestion.value);
                validAutocomplete();
            } // Si on a une suggestion et quon appuie sur Entrer directement
            else if (arraySuggestions.length) {
                selectedSuggestion = getPrevNextSuggestion(selectedSuggestion, 'next') || null;
                updateInputElementValue(inputElement, selectedSuggestion.label);
                SaveGeoloc(selectedSuggestion.value);
                validAutocomplete();
            }
        }
    });

    inputElement.addEventListener('blur', () => {
        hideAllErrorMessages();
    });

    document.addEventListener('click', (event) => {
        if (!inputElement.contains(event.target) && !$suggestions.contains(event.target)) {
            $suggestions.classList.add('suggestion_hidden');
            hideAllErrorMessages();
            arraySuggestions = {};
            selectedSuggestion = null;
            currentIndexSuggestion = 0;
        }
    });
}
