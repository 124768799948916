import grsAutocompleteInit from './google_suggestion_autocomplete.js';
import isAbTestEnabled from './abtest.js';

const desktopSearchConf = {
    selectors: {
        searchInputSelector: 'searchbar-input',
        searchFormSelector: 'searchbar-form',
        searchSubmitSelector: 'searchbar-button'
    },
    resultLimit: {
        productsLimit: 4,
        suggestionsLimit: 5
    },
    searchInputDisplayedClass: 'da-autocomplete-suggestions--is-displayed',
    mobileDesktopDisplay: 'flex',
    navigationUrls: {
        searchPage: {
            path: '/nav/recherche',
            pathSuffix: '.html'
        },
        productPage: '/nav/codic/'
    },
    breakTitleAt: 30
};

const $searchBarForm = document.getElementById(desktopSearchConf.selectors.searchFormSelector);
const $searchbarInput = document.getElementById(desktopSearchConf.selectors.searchInputSelector);

const ressourcesInit = () => {
    const searchContainer = document.querySelector('.search-container');

    isAbTestEnabled('avec_google_search').then((isSearchGRS) => {
        if (isSearchGRS.activated) {
            searchContainer.id = 'grs-autocomplete-result-container';
            searchContainer.classList.add('grs-autocomplete');
            grsAutocompleteInit();
        }
    });
};

export default function init() {
    $searchBarForm.addEventListener('submit', (event) => {
        if ($searchbarInput.value < 2 || $searchbarInput.value === '' || $searchbarInput.value === undefined) {
            event.preventDefault();
        }
    });

    $searchbarInput.addEventListener(
        'focus',
        () => {
            ressourcesInit();
        },
        { once: true }
    );
}
